import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {HashLink} from "react-router-hash-link";
import axios from 'axios';
import NaturalPersonRegisterForm from "./NaturalPersonRegisterForm";
import CompanyRegisterForm from "./CompanyRegisterForm";
import {apiBaseUrl, checkMail} from "../services/api";
import {validateEmail} from "../functions/functions";
import {useToasts} from "../hooks/useToasts";

const customStyles = {
    content: {
        padding: '40px',
        borderRadius: '10px',
        zIndex: 1000,
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 999,
    },
};

const pretupClientSpace = process.env.REACT_APP_USER_DASHBOARD;


const Register = ({onRequestClose}) => {
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [siren, setSiren] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [sponsorCode, setSponsorCode] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const [formType, setFormType] = useState('naturalPersonRegisterForm');
    const [error, setError] = useState(null);
    const [inscriptionOk, setInscriptionOk] = useState(null);
    const [isCguAccepted, setIsCguAccepted] = useState(false);
    const [isLemonwayCguAccepted, setIsLemonwayCguAccepted] = useState(false);
    const [isMailCheckingByApi, setIsMailCheckingByApi] = useState(false);
    const [isEmailUsed, setIsEmailUsed] = useState(false);
    const [civilite, setCivilite] = useState('M');
    const {pushToast} = useToasts();
    const location = useLocation();
    const navigate = useNavigate();

    // Si l'utilisateur a été redirigée ici depuis
    // la page /dynamisez-votre-epargne-jusqu-a-11-pourcent
    useEffect(() => {
        if (location.state?.form) {
            // console.log("form ", location.state.form);
            const form = location.state.form;

            if (form.firstname) {
                setFirstName(form.firstname);
            }
            if (form.lastname) {
                setLastName(form.lastname);
            }
            if (form.email) {
                setEmail(form.email);
            }
            // On "simule" une redirection vers cette même page
            // en nettoyant le state, cela evite le remplissage
            // du form quand l'utilisateur actualise la page.
            navigate(".", {replace: true, state: {}});
        }
    }, [location.state])

    const handleEmailBlur = async () => {
        if (!validateEmail(email)) {
            return;
        }

        await handleCheckMailApi(email)


    };

    const handleCheckMailApi = async (email) => {
        if (!isMailCheckingByApi) {

            setIsMailCheckingByApi(true);
            const isValid = await checkMail(email);
            setIsMailCheckingByApi(false);

            if (isValid) {
                setIsEmailUsed(false);
                if (error === "Cet email est déjà utilisé") {
                    setError("");
                }
            } else {
                setIsEmailUsed(true);
                setError("Cet email est déjà utilisé");
            }
        }
    }
    const handleNaturalPersonSubmit = async (e) => {
        e.preventDefault();
        if (!validateNaturalPersonForm()) {
            return;
        }
        // const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

        const datas = {
            statutlegal: 'physique',
            firstname: firstName,
            lastname: lastName,
            password: password,
            password2: confirmPassword,
            birthdate: birthdate,
            email: email,
            civilite: civilite,
            valid_cgu: true,
            valid_smoney: true,
        }

        if (sponsorCode) {
            datas.codeParrain = sponsorCode;
        }

        if (promoCode) {
            datas.codePromo = promoCode;
        }

        try {
            const response = await axios.post(`${apiBaseUrl}/register/lender`, datas);
            const {message} = response.data;
            const endMessage = message.slice(-1) === "." ?
                " Redirection vers la page de connexion..." :
                ". Redirection vers la page de connexion...";

            setError("");
            pushToast({
                content: message + endMessage,
                type: "success",
                position: "topCenter",
                duration: 8
            })
            clearForm();
            setTimeout(() => {
                window.location.assign(`${pretupClientSpace}/login.php`);
            }, 8000);

        } catch (error) {

            console.error('API Error:', error.response ? error.response.data : error.message);
            const message = error.response ? error.response.data.message : error.message;
            setError('Erreur lors de l\'inscription : ' + message);
            setInscriptionOk(null);
        }
    };

    const handleCompanySubmit = async (e) => {
        e.preventDefault();
        if (!validateCompanyForm()) {
            return;
        }

        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        const datas = {
            civilite: civilite,
            statutlegal: 'morale',
            firstname: firstName,
            lastname: lastName,
            password: password,
            password2: confirmPassword,
            birthdate: birthdate,
            email: email,
            companyName: companyName,
            siren: siren,
            valid_cgu: true,
            valid_smoney: true,
        }

        if (sponsorCode) {
            datas.codeParrain = sponsorCode;
        }

        if (promoCode) {
            datas.codePromo = promoCode;
        }

        try {
            const response = await axios.post(`${apiBaseUrl}/register/lender/company`, datas);
            const {message} = response.data;
            const endMessage = message.slice(-1) === "." ?
                " Redirection vers la page de connexion..." :
                ". Redirection vers la page de connexion...";

            setError("");
            pushToast({
                content: message + endMessage,
                type: "success",
                position: "topCenter",
                duration: 8
            })
            clearForm();
            setTimeout(() => {
                window.location.assign(`${pretupClientSpace}/login.php`);
            }, 8000);

        } catch (error) {

            console.error('API Error:', error.response ? error.response.data : error.message);
            const message = error.response ? error.response.data.message : error.message;
            setError('Erreur lors de l\'inscription : ' + message);
            setInscriptionOk(null);
        }
    };

    const validateNaturalPersonForm = () => {
        const testFirstname = validateFirstName();

        if (!validateFirstName()) {
            return false;
        }

        if (!validateLastName()) {
            return false;
        }

        if (!isLastnameFirstnameDifferents()) {
            setError("Le nom et le prénom doivent être différents");
            return false;
        }

        if (!validateEmail(email)) {
            setError("L'email n'est pas valide");
            return false;
        }

        if (isEmailUsed) {
            setError("L'email est déjà utilisé");
            return false;
        }

        if (isMailCheckingByApi) {
            setError("L'email est en cours de vérification. Réessayez dans 5 secondes.");
            return false;
        }

        if (!civilite) {
            setError("La civilite est requise");
            return false
        }

        if (!isAdult()) {
            setError("Vous devez être majeur pour pouvoir vous inscrire");
            return false;
        }

        if (!passwordsMatch()) {
            setError("Les deux mots de passe doivent être identiques");
            return false;
        }

        if (!validatePassword()) {
            setError("Le mot de passe ne satisfait pas les critères");
            return false;
        }

        if (!isCguAccepted) {
            setError("Vous devez accepter les conditions générales d'utilisation");
            return false;
        }

        if (!isLemonwayCguAccepted) {
            setError("Vous devez accepter les conditions générales de l'établissement de payement");
            return false;
        }

        return true;
    }

    const validateCompanyForm = () => {
        if (!validateNaturalPersonForm()) {
            return false;
        }

        if (!validateSiren()) {
            return false;
        }

        if (!validateCompanyName()) {
            return false;
        }

        return true;
    }

    const onConnexionBtnCLick = () => {
        window.location.assign(`${pretupClientSpace}/login.php`);
    };

    const isAdult = () => {
        const birthDate = new Date(birthdate);

        const today = new Date();

        let age = today.getFullYear() - birthDate.getFullYear();

        // Vérifier si l'anniversaire de cette année est passé
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age >= 18;
    }

    const isLastnameFirstnameDifferents = () => {
        return firstName !== lastName;
    }

    const validatePassword = () => {
        return (
            password.length >= 12 &&
            /[A-Z]/.test(password) &&
            /\d/.test(password) &&
            /[@$!%*?&_\-#^+=(){}[\]:;"'<>,./\\|~`]/.test(password) &&
            passwordsMatch()
        );
    };

    const passwordsMatch = () => {
        if (password.length > 0 && confirmPassword.length > 0) {
            return password === confirmPassword;
        } else {
            return false;
        }
    };

    const clearForm = () => {
        setLastName('');
        setFirstName('');
        setEmail('');
        setBirthdate('');
        setPassword('');
        setConfirmPassword('');
        setCivilite('');
        setSiren('');
        setCompanyName('');
        setSponsorCode('');
        setPromoCode('');
        setError(null);
        setIsCguAccepted(false);
        setIsLemonwayCguAccepted(false);
    };


    const validateEmailApi = () => {
        return true;
    }

    /**
     * Renvoie true ou l'erreur sous forme de string
     * @returns {boolean|string}
     */
    const validateFirstName = () => {
        const withoutSpaces = firstName.replace(/\s+/g, "");
        if (withoutSpaces.length <= 2 ) {
            setError("Le prénom doit contenir plus de 2 caractères  (espaces exclus)");
            return false;
        }

        if (/\d/.test(firstName)) {
            setError("Le prénom ne doit pas contenir de chiffres");
            return false;
        }

        return true;
    }

    /**
     * Renvoie true ou l'erreur sous forme de string
     * @returns {boolean|string}
     */
    const validateLastName = () => {
        const withoutSpaces = lastName.replace(/\s+/g, "");
        if (withoutSpaces.length <= 2 ) {
            setError("Le nom doit contenir plus de 2 caractères (espaces exclus)");
            return false;
        }

        if (/\d/.test(lastName)) {
            setError("Le nom ne doit pas contenir de chiffres");
            return false;
        }

        return true;
    }

    const validateSiren = () => {
        if (siren.length !== 9) {
            setError("Le n° de SIREN doit comporter 9 caractères");
            return false;
        } else {
            return true;
        }
    }

    const validateCompanyName = () => {
        return !!companyName;
    }

    const handleFormChange = (type) => {
        setEmail('');
        setError('');

        if (type === 'naturalPersonRegisterForm') {
            setFormType('naturalPersonRegisterForm');
            setSponsorCode('');
            setPromoCode('');
        } else if (type === 'companyRegisterForm') {
            setFormType('companyRegisterForm');
        }
    }


    return (

        <div className="mb-6 md:mb-12 md:mx-auto md:w-3/5">
            <h2 className="mb-4 text-3xl text-secondary">Inscrivez-vous pour investir dans les PME</h2>
            <p className={"mb-10 text-lg text-secondary"}>Rejoignez une communauté d'investisseurs engagée, investissez dans l'économie réelle</p>
            <div className="mb-4 mt-2 block w-full max-w-2xl">
                <p className="block text-sm font-medium my-2">Statut juridique de votre compte investisseur</p>
                <div className="flex">
                    <button
                        type="button"
                        className={formType === "naturalPersonRegisterForm" ?
                            "w-48 py-2 px-4 bg-secondary hover:bg-secondaryLight text-white rounded-md" :
                            "w-48 py-2 px-4 bg-white hover:bg-secondary hover:text-white text-black rounded-md"}
                        onClick={() => handleFormChange("naturalPersonRegisterForm")}
                        disabled={formType === "naturalPersonRegisterForm"}
                    >
                        Personne physique
                    </button>
                    <button
                        type="button"
                        className={formType === "companyRegisterForm" ?
                            "w-48 py-2 px-4 ml-3 bg-secondary hover:bg-secondaryLight text-white rounded-md" :
                            "w-48 py-2 px-4 ml-3 bg-white hover:bg-secondary hover:text-white text-black rounded-md"}
                        onClick={() => handleFormChange("companyRegisterForm")}
                        disabled={formType === "companyRegisterForm"}
                    >
                        Personne morale
                    </button>
                </div>
            </div>
            {formType === "naturalPersonRegisterForm" ?
                (<NaturalPersonRegisterForm lastName={lastName}
                                            setLastName={setLastName}
                                            firstName={firstName}
                                            setFirstName={setFirstName}
                                            email={email}
                                            setEmail={setEmail}
                                            onRequestClose={onRequestClose}
                                            password={password}
                                            setPassword={setPassword}
                                            confirmPassword={confirmPassword}
                                            handleSubmit={handleNaturalPersonSubmit}
                                            birthdate={birthdate}
                                            setBirthdate={setBirthdate}
                                            setConfirmPassword={setConfirmPassword}
                                            error={error}
                                            setError={setError}
                    // inscriptionOk={inscriptionOk}
                    // setInscriptionOk={setInscriptionOk}
                                            promoCode={promoCode}
                                            setPromoCode={setPromoCode}
                                            sponsorCode={sponsorCode}
                                            setSponsorCode={setSponsorCode}
                                            isCguAccepted={isCguAccepted}
                                            setIsCguAccepted={setIsCguAccepted}
                                            isLemonwayCguAccepted={isLemonwayCguAccepted}
                                            setIsLemonwayCguAccepted={setIsLemonwayCguAccepted}
                                            passwordsMatch={passwordsMatch}
                                            handleEmailBlur={handleEmailBlur}
                                            setCivilite={setCivilite}
                                            civilite={civilite}
                />) :
                (<CompanyRegisterForm lastName={lastName}
                                      civilite={civilite}
                                      setCivilite={setCivilite}
                                      setLastName={setLastName}
                                      firstName={firstName}
                                      setFirstName={setFirstName}
                                      email={email}
                                      setEmail={setEmail}
                                      onRequestClose={onRequestClose}
                                      password={password}
                                      setPassword={setPassword}
                                      confirmPassword={confirmPassword}
                                      handleSubmit={handleCompanySubmit}
                                      birthdate={birthdate}
                                      setBirthdate={setBirthdate}
                                      setConfirmPassword={setConfirmPassword}
                                      error={error}
                                      setError={setError}
                    // inscriptionOk={inscriptionOk}
                    // setInscriptionOk={setInscriptionOk}
                                      promoCode={promoCode}
                                      setPromoCode={setPromoCode}
                                      sponsorCode={sponsorCode}
                                      setSponsorCode={setSponsorCode}
                                      siren={siren}
                                      setSiren={setSiren}
                                      companyName={companyName}
                                      setCompanyName={setCompanyName}
                                      isCguAccepted={isCguAccepted}
                                      setIsCguAccepted={setIsCguAccepted}
                                      isLemonwayCguAccepted={isLemonwayCguAccepted}
                                      setIsLemonwayCguAccepted={setIsLemonwayCguAccepted}
                                      passwordsMatch={passwordsMatch}
                                      handleEmailBlur={handleEmailBlur}
                />)
            }
            <p className="mt-4 text-sm text-center">
                Vous avez déjà un compte ?{' '}
                <button onClick={onConnexionBtnCLick} className="text-primary hover:underline">
                    Connectez-vous ici
                </button>
            </p>
            <p className="mt-4 text-sm text-center">
                Vous souhaitez emprunter ?{' '}
                <HashLink
                    to="/emprunteur#fundingForm"
                    className="text-primary hover:underline"
                >
                    Cliquez ici
                </HashLink>
            </p>
        </div>
    );
};

export default Register;

/**
 * Partie du dessus
 *  <Modal
 *             isOpen={isOpen}
 *             onRequestClose={onRequestClose}
 *             style={customStyles}
 *             contentLabel="Register Modal"
 *             className="bg-white rounded-lg shadow-lg"
 *             overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center"
 *         >
 *             <div className="absolute top-0 right-0 pt-4 pr-4">
 *                 <button onClick={onRequestClose} className="text-gray-400 hover:text-gray-600 focus:outline-none">
 *                     <FaTimes className="w-6 h-6"/>
 *                 </button>
 *             </div>
 *
 * Partie du dessous :
 *
 * </Modal>
 *
 */